<template>
    <van-cell title="昵称" :value="nickname" is-link @click="dialog=true"/>
    <el-dialog width="70%" :model-value="dialog" @closed="dialog=false">
        <el-input v-model="new_name" placeholder="请输入新昵称"></el-input>
        <template #footer>
            <div class="center">
                <el-button size="small" type="primary" @click="upd_user">确定</el-button>
            </div>
        </template>
    </el-dialog>
</template>

<script>
  import {update_user} from "@net/member";

  let timer = null
  export default {
    name: "update_name",
    data() {
      return {
        nickname: '',
        dialog: false,
        new_name: ''
      }
    },
    props: {
      name: {
        type: String,
        default: ''
      }
    },
    emits: ['back'],
    created() {
      this.nickname = this.name;
      this.new_name = this.name;
    },
    methods: {
      upd_user() {
        if (this.new_name == '') {
          this.$toast({message: '请输入新的昵称', className: 'toast-vant'})
          return
        }
        if (this.new_name == this.nickname) {
          this.$toast({message: '无需修改', className: 'toast-vant'})
          this.dialog = false
          return
        }
        let obj = {nickname: this.new_name, is_login: 1}
        this.Utils.debounce(() => {
              update_user(obj).then(res => {
                if (res.business_code == 200) {
                  this.$toast({message: '修改成功', className: 'toast-vant'})
                  this.nickname = this.new_name
                  this.dialog = false
                  this.$emit('back', 'upd_name', this.new_name)
                } else {
                  this.$toast({message: res.msg, className: 'toast-vant'})
                }
              })
            }
        )
      }
    }
  }
</script>

<style scoped>

</style>