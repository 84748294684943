<template>
    <van-cell title="退出登录" is-link @click="dialog=true"/>
    <el-dialog width="70%" :model-value="dialog" @closed="dialog=false">
        <div class="font14 color-black font-bold">确定退出登录吗？</div>
        <template #footer>
            <div class="center">
                <el-button size="small" type="warning" @click="exit">确定</el-button>
            </div>
        </template>
    </el-dialog>
</template>

<script>
  import LocalStorage from '@/localStorage/localstorage'

  let timer = null
  export default {
    name: "member_exit",
    data() {
      return {
        dialog: false,
      }
    },
    created() {

    },
    methods: {
      exit() {
        this.Utils.debounce(() => {
          LocalStorage.remove_val('openid')
          this.$router.replace('/home')
        })
      }
    }
  }
</script>

<style scoped>

</style>