<template>
  <el-upload
          :class="class_page"
          accept="image/png,image/jpg,image/jpeg"
          :data="params"
          :action=upload_url
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload"
  >
    <van-image
            class="avatar"
            round
            fit="'cover"
            :src="imgUrl"
            v-if="imgUrl"
    />
    <el-icon class="avatar-uploader-icon" v-else><upload /></el-icon>
  </el-upload>
</template>

<script>
  import LocalStorage from '@/localStorage/localstorage'
  import {Upload} from '@element-plus/icons'
  export default {
    name: "UploadImg",
    components:{Upload},
    data() {
      return {
        imgUrl: '',
        upload_url:'',
        params:{}
      }
    },
    emits: ['back'],
    props: {
      type_str: {
        default: '',
        type: String
      },
      default_img: {
        default: '',
        type: String
      },
      class_page: {
        default: 'avatar-uploader',
        type: String
      },
      img_path:{
        type:String,
        default:''
      },
    },
    created() {
      this.params={
        url:this.img_path,
        type:this.type_str
      }
      if(this.type_str=='face'){
        this.params.key=this.Utils.public_var('web_key')
        this.params.openid=this.Utils.public_var('openid')
      }
      if (this.default_img != '') {
        this.imgUrl = this.default_img
      }
      if(process.env.VUE_APP_FLAG=='development'){
        this.upload_url="/testApi/open/upload/uploadImg";
      }else if(process.env.VUE_APP_FLAG=='production'){
        this.upload_url=this.Utils.public_var('domain')+"/open/upload/uploadImg";
      }
    },
    methods: {
      handleAvatarSuccess(res, file) {
        if(res.status){

          this.imgUrl=res.url+"?v="+new Date().getTime()//解决更新同名图片不加载问题

          if(this.type_str!='face'){
            this.$emit('back', this.type_str, res.url)
          }
        }

      },
      beforeAvatarUpload(file) {
        //const isJPG = file.type === 'image/jpeg'
        const isLt2M = file.size / 1024 / 1024 < 2

        /*if (!isJPG) {
            this.$message.error('Avatar picture must be JPG format!')
        }*/
        if (!isLt2M) {
          this.$message.error('Avatar picture size can not exceed 2MB!')
        }
        return isLt2M
      }
    }
  }
</script>

<style lang="scss" scoped>
  .face{
    height: 100%;
    width: 100%;
    ::v-deep(.el-upload){
      height: 100%;
      width: 100%;
      border-radius: 50%;
      border:1px solid #d9d9d9
    }
    .avatar-uploader-icon,.avatar {
      height: 100%;
      width: 100%;
      border-radius: 50%;
      line-height: 80px;
    }
  }

</style>