<template>
  <div class="center mt20">
    <div class="face-dom">
      <upload-img type_str="face" :default_img="data.face" class_page="face" v-if="refresh_state" img_path="/static/img/member_face"></upload-img>
    </div>
  </div>
  <div class="text-center color-black font16 mt10 font-bold">{{data.nickname}}</div>
  <div class="mt20">
    <van-cell title="账号" :value="data.account"/>
    <update_name :name="data.nickname" @back="back" v-if="refresh_state"></update_name>
    <change_email :email="data.email" v-if="refresh_state"></change_email>
    <member_exit></member_exit>
  </div>
  <tab-bar></tab-bar>
</template>

<script>
  import TabBar from "@c/tabBar/tabBar";
  import {member_info} from "@net/member";
  import Update_name from "./member/components/update_name";
  import Change_email from "./member/components/change_email";
  import UploadImg from "../components/upload/UploadImg";
  import Member_exit from "./member/components/member_exit";

  export default {
    name: "mine",
    components: {Member_exit, UploadImg, Change_email, Update_name, TabBar},
    data(){
      return{
        data:{},
        refresh_state:true,
      }
    },
    created() {
      if (!this.Utils.has_login()) {
        this.Utils.go_login(this.$route.path,this.$route.query)
      }else {
        this.get_info()
      }
    },
    mounted() {

    },
    methods: {
      get_info(){
        member_info({is_login:1}).then(res=>{
          if(res.business_code==200){
            this.data=res.data
            this.refresh_state=false
            this.$nextTick(function () {
              this.refresh_state=true
            })
          }else {
            this.Utils.go_login(this.$route.path,this.$route.query)
          }
        })
      },
      back(type,val){
        if(type=='upd_name'){
          this.data.nickname=val
        }
      }
    }
  }
</script>

<style scoped>
  .face-dom{
    height: 80px;
    width: 80px;
  }
</style>