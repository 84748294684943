import http from "@u/http";

export function send_email_code(params) {
  return http.post('/apis/login/send_email',params,'/testApi')
}

export function email_register(params) {
  return http.post('/apis/login/email_login',params,'/testApi')
}

export function login_password(params) {//密码登录
  return http.post('/apis/login/login_password',params,'/testApi')
}