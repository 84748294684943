<template>
    <van-cell title="换绑邮箱" :value="old_email" is-link @click="dialog=true"/>
    <el-dialog width="90%" :model-value="dialog" @closed="dialog=false">
        <el-form label-width="70px" label-position="left">
            <el-form-item class="relative" label="邮箱地址">
                <el-input v-model="new_email" placeholder="请输入新邮箱地址"></el-input>
            </el-form-item>

            <el-form-item class="relative mt10" label="验证码">
                <el-input v-model="code" placeholder="请输入验证码"></el-input>
                <div class="absolute font12 code" style="color: #ea5503;" v-if="state==0" @click="get_code">发送验证码</div>
                <div class="absolute font12 code color-red" v-if="state==1">{{time}}秒后重新获取</div>
                <div class="absolute font12 code" style="color: #ea5503;" v-if="state==2" @click="get_code">重新获取</div>
            </el-form-item>
        </el-form>

        <template #footer>
            <div class="center">
                <el-button size="small" type="primary" @click="exchange_email">确定</el-button>
            </div>
        </template>
    </el-dialog>
</template>

<script>
  import {exc_email} from "@net/member";
  import {send_email_code} from "@net/login/login";


  export default {
    name: "change_email",
    data() {
      return {
        old_email: '',
        dialog: false,
        new_email: '',
        code:'',
        state:'',
        time:180
      }
    },
    props: {
      email: {
        type: String,
        default: ''
      }
    },
    created() {
      this.old_email=this.email
    },
    methods: {
      exchange_email() {
        if (this.new_email == '') {
          this.$toast({message: '请输入新的邮箱', className: 'toast-vant'})
          return
        }
        if (this.new_email == this.old_email) {
          this.$toast({message: '无需修改', className: 'toast-vant'})
          this.dialog = false
          return
        }
        if(this.code==''){
          this.$toast({message: '请输入验证码', className: 'toast-vant'})
          return;
        }
        let obj = {email: this.new_email, is_login: 1,code:this.code}
        this.Utils.debounce(() => {
          exc_email(obj).then(res => {
            if (res.business_code == 200) {
              this.$toast({message: '修改成功', className: 'toast-vant'})
              this.old_email = this.new_email
              this.dialog = false
            } else {
              this.$toast({message: res.msg, className: 'toast-vant'})
            }
          })
        })
      },
      get_code(){//获取验证码
        if(this.new_email==''){
          this.Utils.Toast('error','请输入邮箱地址')
          return
        }
        let obj={}
        obj.email_addr=this.new_email

        this.Utils.debounce(()=>{
          send_email_code(obj).then(res=>{
            if(res.business_code==200){
              this.Utils.Toast('success','发送成功')
              this.state=1
              this.get_timer()
            }else {
              this.Utils.Toast('error',res.msg)
            }
            clearTimeout(timer)
          })
        })
      },
      get_timer(){//计时器
        let times=setInterval(()=>{
          if(this.time>0){
            this.time--
          }else {
            this.state=2
            clearInterval(times)
          }
        },1000)
      },
    }
  }
</script>

<style scoped>
    .code{
        top: 50%;
        right: 5%;
        transform: translateY(-50%);
    }
</style>