import http from "@u/http";

export function member_info(params) {
  return http.post('/apis/member/member_info',params,'/testApi')
}

export function update_user(params) {
  return http.post('/apis/member/update_user',params,'/testApi')
}
export function exc_email(params) {//更换邮箱
  return http.post('/apis/member/exchange_email',params,'/testApi')
}